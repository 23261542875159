/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/assets/styles/style.scss"

import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faLinkedinIn,
  faFacebook,
  faTwitter,
  faInstagram,
  faGithub,
} from "@fortawesome/free-brands-svg-icons"

library.add(faLinkedinIn, faFacebook, faTwitter, faInstagram, faGithub)
